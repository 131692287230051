.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 20px 150px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.formGroup label {
    margin-bottom: 5px;
    font-weight: bold;
}

.formGroup input {
    padding: 8px;
    font-size: 16px;
}

.scheduleGroup {
    margin-bottom: 0px;
}

.scheduleGroup h4 {
    padding: 0;
    margin: 0 0 6px;
}

.hourInputs {
    display: flex;
    align-items: center;
    gap: 10px;
}

.submitButton {
    padding: 10px 20px;
    background-color: var(--branding-purple);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.submitButton:hover {
    background-color: #45a049;
}
