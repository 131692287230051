.header {
    width: 100%;
    height: 85px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: start;
    padding: 0;
    background-color: transparent;
    flex-wrap: wrap;
    position: relative;
    z-index: 9999999;
    box-sizing: border-box;
}

@media screen and (min-width: 778px) {
    .header {
        margin-bottom: 0px;
    }
}

.settingsHeader {
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 0;
    background-color: var(--background-color);
    flex-wrap: wrap;
    position: fixed;
    z-index: 10;
}

.settingsContent {
    padding: 70px 0 0;
    position: relative;
    z-index: 2;
}

.upperHeader {
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-top: 15px;
}

.upperSettingsHeader {
    width: 100%;
    padding: 0 15px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.upperHeaderCentered {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.backLink, .backButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.backLink svg, .backButton svg {
    font-size: 2rem;
    color: var(--text-color);
}

.lowerHeader {
    height: 33px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.locationHeader {
    width: auto;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 9px;
    color: var(--white-text);
    padding: 0 9px;
    height: 42px;
    margin-right: 9px;
    background-color: var(--secondary-color);
    background-color: var(--branding-purple);
    border-radius: 9px;
}

.locationHeader {

}

.Navigation {
    width: 94%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.city {
    font-size: 12px;

}

.logoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    position: fixed;
    top: 12px;
    left: 6px;
    z-index: 999999999;
}

.logoWrapFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}

.logo {
    width: 84px;
}

.logoBeta {
    width: 114px;
    margin: -6px 0 0 -6px;
}

.headerBtns {

    position: relative;
}

.signInBtn {
    cursor: pointer;
    font-size: 0.75rem;
    background: var(--branding-purple);
    color: var(--white-text);
    border-radius: 9px;
    height: 42px;
    padding: 9px;
    line-height: 1.13;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    transition: color 0.25s;
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
}

.profileLink {
    width: 105px;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 0 9px;
    color: var(--text-color);
}


/* New styles for the bottom sheet */
.bottomSheetOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 99999999;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease;
}

.bottomSheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--secondary-color);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.3s ease-out;
    transform: translateY(100%);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    transition: all 0.3s ease-out;
}

.bottomSheet.open {
    transform: translateY(3px);
    transition: all 0.3s ease-out;
    max-width: 640px;
    margin: auto;
}

.bottomSheetContent {
    padding: 20px;
}

.bottomSheetContent h2 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
}

.locationIcon {
    background-color: var(--branding-purple);
}

.locationInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    background-color: var(--background-color) !important;
    color: var(--white-text) !important;
}

.changeLocationButton,
.closeBottomSheetButton {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.changeLocationButton {
    background-color: var(--branding-purple);
    color: white;
}

.changeLocationButton:hover {
    background-color: var(--branding-purple);
}

.closeBottomSheetButton {
    background-color: #f8f9fa;
    color: #343a40;
}

.closeBottomSheetButton:hover {
    background-color: #e2e6ea;
}

.Navigation {
    transition: transform 0.3s ease-in-out;
}

.mainNavTour {
    transform: translateX(-50%) translateY(0%) !important;
}

.Navigation.hidden {
    transform: translateY(100%) !important;
}

/* Responsive adjustments */
@media (max-width: 778px) {
    .settingsHeader {
        height: 72px;
    }
    .logoWrap {
        position: relative;
        top: 3px;
    }
    .bottomSheetContent {
        padding: 15px;
    }

    .locationInput,
    .changeLocationButton,
    .closeBottomSheetButton {
        font-size: 14px;
    }

    .signInBtn {
        height: 38px;
    }
}
