
a {
    text-decoration: none;
    cursor: pointer;
}
a:-webkit-any-link:focus-visible, button:focus-visible {
    outline: none !important;
}

input, select, textarea {
    box-sizing: border-box;
    border-radius: 9px;
    border: none;
}

button {
    cursor: pointer;
}


.capitalize {
    text-transform: capitalize;
}

.flex-col {
    display: flex;
    flex-direction: column;
}
.gap-2 {
    gap: 1rem;
}
.gap-0-25 {
    gap: 0.25rem;
}
.flex-wrap {
    flex-wrap: wrap;
}
.w-full {
    width: 100%;
    max-width: none !important;
}
.max-w-49 {
    max-width: 49% !important;
}
.flex-min-width {
    flex-basis: min-content !important;
}
.customHighlight {
    box-shadow: rgb(33, 33, 33) 0px 0px 1px 2px, rgb(33, 33, 33, 0.86) 0px 0px 0px 5000px !important;
}

.menuButtonCustomHighlight {
    transform: translate(-27%, -13%);
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 10px;
    height: 50px !important;
    width: 50px !important;
}

.menuButtonCustomHighlightNotify {
    transform: translate(-12%, -13%);
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 10px;
    height: 50px !important;
    width: 50px !important;

}

.introjs-highlight {
    position: relative !important;
    z-index: 9999 !important;
}

.tour-vote-container {
    position: relative;
    z-index: 1;
}

.introjs-tooltiptext {
    padding: 3px 12px;
}

.introjs-tooltiptext {
    color: var(--white-text);
    font-size: 18px;
}

.introjs-tooltip {
    background-color: rgba(0,0,0,0.3);
    border-radius: 12px;
    box-shadow: 0 0 10px #444;

}
.introjs-progressbar {
    background-color: var(--branding-purple);
}

.introjs-button {
    border-radius: 9px;
    background-color: var(--branding-purple);
    text-shadow: none;
    color: var(--white-text);
    border: none;
}
.introjs-arrow.top {
    border-bottom-color: var(--dark-secondary);
}
.introjs-arrow.bottom {
    border-top-color: var(--dark-secondary);
}
.introjs-arrow.left {
    border-right-color: var(--dark-secondary);
}
.introjs-arrow.right {
    border-left-color: var(--dark-secondary);
}
.introjs-arrow.bottom-middle, .introjs-arrow.bottom-right, .introjs-arrow.bottom-left {
    border-top-color: var(--dark-secondary);
}
/* Global Form Styles (Bootstrap-like) */

/* Form containers */
.form-group {
    margin-bottom: 1rem;
}

/* Labels */
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

/* Inputs, Selects, and Textareas */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="url"],
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="search"],
select,
textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 9px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Focus states */
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="search"]:focus,
select:focus,
textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--branding-purple);
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--branding-purple);
}

/* Checkboxes and Radios */
.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-label {
    margin-bottom: 0;
}

/* Buttons */
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

/* Form validation */
.is-valid {
    border-color: #28a745;
}

.is-invalid {
    border-color: #dc3545;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.is-invalid ~ .invalid-feedback {
    display: block;
}

/* Responsive form layout */
@media (min-width: 576px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-check {
        display: inline-block;
        padding-left: 0;
        margin-right: 0.5rem;
    }
}

.btn-grad {
    background-image: var(--branding-purple-gradient);
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 20px #eee;
}

.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
