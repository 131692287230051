:root {
    /* Radius */
    --border-radius-tiny: 6px;
    --border-radius-xxs: 9px;
    --border-radius-xs: 12px;
    --border-radius-sm: 15px;
    --border-radius-lg: 21px;
    --border-radius-xl: 30px;

    --price-border-dark: #666;
    --price-border-light: #333;

    --product-border-dark: #666;
    --product-border-light: #ddd;



    /* Badges */
    --light-discount-badge: var(--branding-green);
    --dark-discount-badge: var(--branding-purple);
    --light-focus-input-color: var(--branding-purple-transparent);
    --dark-focus-input-color: var(--branding-green-transparent);

    /* Tabs */
    --light-search-tab-bg: var(--branding-purple);
    --light-search-tab-fg: var(--white-text);
    --dark-search-tab-bg: var(--branding-green);
    --dark-search-tab-fg: var(--white-text);


    /* Pills */
    --dark-pill-bg: rgba(123,123,123,0.3);
    --light-pill-bg: rgba(196,196,196,0.3);

    /* Pull Indicator */
    --dark-pull: rgba(255, 255, 255, 0.2);
    --light-pull: rgba(139, 139, 139, 0.5);

    /* Transparent Backgrounds */
    --light-background-transparent: rgba(27, 27, 27, 0.54);
    --dark-background-transparent: rgba(214, 214, 214, 0.33);
    --light-product-bg-transparent: rgba(207, 207, 207,0.33);
    --dark-product-bg-transparent: rgba(0, 0, 0,0.33);



    /* Light mode colors */
    --light-nav-background: rgba(255, 255, 255, 0.9);
    --light-abs-background: rgba(255, 255, 255, 0.396);
    --light-settings-icon: var(--branding-purple);
    --light-accent-background: #F6F6F6;
    --light-background: #ffffff;
    --light-text: #333333;
    --light-text-secondary: #666666;
    --light-primary: #cca353;
    --light-secondary: #dddddd;
    --light-accent: #ff6b6b;
    --badge-accent: #fb5252;
    --light-thirdary: #eeeeee;
    --light-icon-transparent: rgba(228, 228, 228, 0.75);

    /* Dark mode colors */
    --dark-nav-background: rgba(0, 0, 0, 0.9);
    --dark-abs-background: rgba(0, 0, 0, 0.396);
    --dark-settings-icon: var(--branding-green);
    --dark-black-background: #000000;
    --dark-background: #1a1a1a;
    --dark-text: #ffffff;
    --dark-text-secondary: #eeeeee;
    --dark-primary: #ffd700;
    --dark-secondary: #333333;
    --dark-thirdary: #666666;
    --dark-accent: #ff9f43;
    --dark-icon-transparent: rgba(102, 102, 102,0.85);
    --darker-icon-transparent: rgba(69, 69, 69,0.85);
    --darkest-icon-transparent: rgba(0, 0, 0,0.33);

    /* Navbar Colors */
    --light-vote-green: green;
    --navbar-color: #98fb98;
    --branding-green: #98fb98;
    --branding-green-dark: #5dc55d;
    --branding-green-transparent: rgba(152, 251, 152, 0.92);
    --black-text: #000000;
    --white-text: #ffffff;
    --profile-info-text: #5b6b9e;
    --branding-purple: #775496;
    --branding-purple-rgb: 119, 84, 150;
    --branding-purple-transparent: #775496eb;
    --branding-purple-light: #9A7DB2;
    --branding-purple-dark: #35253d;
    --branding-blue: #5e95f9;

    --dark-vote-green: var(--branding-green);

    --lineage-hybrid: #0e785f;
    --lineage-sativa: #f17c54;
    --lineage-indica: var(--branding-purple);


    --branding-purple-gradient: linear-gradient(to right, #4776E6 0%, var(--branding-purple)  51%, #4776E6  100%);
    --branding-purple-glow: 0 0 20px #eee;

    --branding-indigo: #5c47e6;




    /* Default to light mode */
    --nav-background: var(--light-nav-background);
    --price-border: var(--price-border-light);
    --settings-icon: var(--light-settings-icon);
    --background-accent-color: var(--light-accent-background);
    --background-color: var(--light-background);
    --background-transparent: var(--light-background-transparent);
    --text-color: var(--light-text);
    --text-color-secondary: var(--light-text-secondary);
    --primary-color: var(--light-primary);
    --secondary-color: var(--light-accent-background);
    --thirdary-color: var(--light-thirdary);
    --quartary-color: var(--light-accent);
    --accent-color: var(--light-accent);
    --icon-transparent: var(--light-icon-transparent);
    --vote-green: var(--light-vote-green);
    --price-color: var(--light-accent);
    --product-border: var(--product-border-dark);
    --focus-input-color: var(--light-focus-input-color);
    --branding-color: var(--branding-purple);
    --search-tab-fg: var(--light-search-tab-fg);
    --search-tab-bg: var(--light-search-tab-bg);
    --product-bg-transparent: var(--light-product-bg-transparent);
    --pill-bg: var(--light-pill-bg);
    --pull: var(--light-pull);

}

/* Dark mode class */
.dark-mode {
    --nav-background: var(--dark-nav-background);
    --price-border: var(--price-border-dark);
    --abs-background: var(--dark-abs-background);
    --settings-icon: var(--dark-settings-icon);
    --background-accent-color: var(--dark-black-background);
    --background-color: var(--dark-background);
    --background-transparent: var(--dark-background-transparent);
    --text-color: var(--dark-text);
    --text-color-secondary: var(--dark-text-secondary);
    --vote-green: var(--dark-vote-green);
    --primary-color: var(--dark-primary);
    --secondary-color: var(--dark-secondary);
    --thirdary-color: var(--dark-thirdary);
    --accent-color: var(--dark-accent);
    --icon-transparent: var(--dark-icon-transparent);
    --icon-darker-transparent: var(--darker-icon-transparent);
    --discount-badge: var(--dark-discount-badge);
    --price-color: var(--light-accent);
    --product-border: var(--product-border-dark);
    --focus-input-color: var(--dark-focus-input-color);
    --branding-color: var(--branding-green);
    --search-tab-fg: var(--dark-search-tab-fg);
    --search-tab-bg: var(--dark-search-tab-bg);
    --product-bg-transparent: var(--dark-product-bg-transparent);
    --pill-bg: var(--dark-pill-bg);
    --pull: var(--dark-pull);

}

/* Dark mode */
@media (prefers-color-scheme: dark) {
    :root {
        --nav-background: var(--dark-nav-background);
        --price-border: var(--price-border-dark);
        --abs-background: var(--dark-abs-background);
        --settings-icon: var(--dark-settings-icon);
        --background-accent-color: var(--dark-black-background);
        --background-color: var(--dark-background);
        --background-transparent: var(--dark-background-transparent);
        --text-color: var(--dark-text);
        --text-color-secondary: var(--dark-text-secondary);
        --primary-color: var(--dark-primary);
        --secondary-color: var(--dark-secondary);
        --thirdary-color: var(--dark-thirdary);
        --accent-color: var(--dark-accent);
        --vote-green: var(--dark-vote-green);
        --icon-transparent: var(--dark-icon-transparent);
        --icon-darker-transparent: var(--darker-icon-transparent);
        --discount-badge: var(--dark-discount-badge);
        --price-color: var(--branding-purple-light);
        --product-border: var(--product-border-dark);
        --focus-input-color: var(--dark-focus-input-color);
        --branding-color: var(--branding-green);
        --search-tab-fg: var(--dark-search-tab-fg);
        --search-tab-bg: var(--dark-search-tab-bg);
        --product-bg-transparent: var(--dark-product-bg-transparent);
        --pill-bg: var(--dark-pill-bg);
        --pull: var(--dark-pull);

    }
}

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

/* Universal Styles */
html {
    font-size: 18px;
}

html,
body {
    margin: 0;
    background-color: var(--background-color);
    color: var(--text-color);
}

@media only screen and (max-width: 560px) {
    html {
        font-size: 15px;
    }
}

input:focus {
    outline: none;
}

body, input, textarea, select {
    font-family: "Plus Jakarta Sans", sans-serif;
}

/* App Styles */
.App {
    font-family: "Plus Jakarta Sans", sans-serif;
    max-width: 100%;
    margin: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.AppResults {
    max-width: 100%;
    margin: auto;
    overflow-x: hidden;
}
.AppExtraDivLargeScreens {
    display: none;
}





.App h1 {
    padding: 0.66rem 0;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 1.8rem;
    color: var(--background-color);
    margin: 0;
}

.noResults {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 45px;
    text-align: center;
}

.productListWrap {
    padding: 0;
    position: relative;
    z-index: 2;
}

.productListWrapPinned {
    padding-top: 54px !important;
}

.businessProductListWrap {
    padding: 0;
    position: relative;
    z-index: 2;
}


.navbarFilterContainer {
    position: relative;
    transition: all 0.3s ease;

}

.navbarFilterContainer.pinned {
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    width: 100%;
    background-color: var(--branding-purple-transparent);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 54px;
    box-shadow: 0 0 36px #fff;
    z-index: 999999;
}

.greenNavbarFilterContainer.pinned1 {
    background-color: var(--branding-green-transparent);

}

.productListWrap {
    padding-top: 0; /* Add padding to prevent content from jumping when NavbarFilter is pinned */
}

@media screen and (min-width: 778px) {
    .AppExtraDivLargeScreens {
        width: 50%;
        display: flex;
    }
    .AppResults {
        display: flex;
        flex-direction: row;
    }

    .AppMainList {
        width: 50%;
        margin-top: 33px;
    }

    .SearchBar {
        width: 50%;
    }

    .productListVirtual {
        max-width: 96%;
    }

    .navbarFilterContainer.pinned {
        width: 50%;
    }
}







/* You can add more specific styles here using the variables */
